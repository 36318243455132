import React from 'react';
import Register_page from '../components/Register_page';


const Register = () => {
    return (
        <>
            <Register_page />
        </>

    );
};

export default Register;