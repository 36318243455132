import React from 'react';
import Home_page from '../components/Home_page';

const Home = () => {
    return (
        <>
            <Home_page />
        </>
    );
};

export default Home;