import React from 'react';
import Error_page from '../components/Error_page';

const Error = () => {
    return (
        <>

            <Error_page />
        </>
    );
};

export default Error;